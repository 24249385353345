<template>
    <font-awesome-icon icon="trash-alt" @click="confirmarExcluir()" size="lg" class="icon-button p-error" title="Excluir" />
</template>

<script>
import AnexosServices from './services';

export default {
    props: {
        anexo: {
            type: Object,
        },
    },

    emits: ['atualizarOcorrencias'],

    methods: {
        confirmarExcluir() {
            this.$confirm.require({
                message: `Tem certeza que deseja excluir o anexo?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.excluir();
                },
            });
        },

        excluir() {
            this.$store.dispatch('addRequest');
            let anexoDto = {
                projetoAnexoId: this.anexo.projetoAnexoId,
                projetoId: this.$route.params.id,
            };
            AnexosServices.excluir(anexoDto).then((response) => {
                if (response && response.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Exclusão do Anexo',
                        detail: 'Anexo excluído com sucesso',
                        life: 3000,
                    });
                    this.$emit('atualizarAnexos', response.data);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Exclusão do Anexo',
                        detail: response.errors[0],
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
