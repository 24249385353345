<template>
    <Button label="Inserir Anexo" icon="pi pi-plus" iconPos="left" @click="abrir()" class="p-button" />
    <anexo-form
        :mostrar="mostrar"
        :reset="resetForm"
        titulo="Inserir Anexo"
        @cancelar="fechar()"
        @salvar="inserir($event)"
        :erros="erros"
        @resetado="resetForm = false"
        :projeto="projeto"
    ></anexo-form>
</template>

<script>
import AnexoForm from './AnexoForm';
import AnexosServices from './services';

export default {
    props: {
        projeto: {
            type: Object,
        },
    },

    components: {
        AnexoForm,
    },

    emits: ['atualizarAnexos'],

    data() {
        return {
            mostrar: false,
            erros: [],
            resetForm: false,
        };
    },

    methods: {
        abrir() {
            this.mostrar = true;
        },

        fechar() {
            if (this.mostrar) {
                this.mostrar = false;
            }
            this.erros = [];
        },

        reset() {
            this.resetForm = true;
            this.erros = [];
        },

        inserir(anexoInserido) {
            this.$store.dispatch('addRequest');
            let anexoDto = {
                projetoId: this.$route.params.id,
                // tipoDocumentoId: anexoInserido.tipoDocumentoId,
                descricao: anexoInserido.descricao,
                arquivo: anexoInserido.arquivo,
            };
            AnexosServices.inserir(anexoDto).then((r) => {
                if (r && r.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Novo anexo',
                        detail: 'Anexo salvo com sucesso',
                        life: 3000,
                    });
                    this.$emit('atualizarAnexos', r.data);
                    this.reset();
                    this.fechar();
                } else {
                    this.erros = r.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
