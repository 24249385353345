import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PROJETOS_PORT}${process.env.VUE_APP_API_PATH}`;
const urlBase = `${api}/projetos`;

export default {
    obterTodos() {
        return axiosJwt.get(`${urlBase}`);
    },

    obterProjetoPorId(id) {
        return axiosJwt.get(`${urlBase}/${id}`);
    },

    inserirProjeto(projeto) {
        return axiosJwt.post(`${urlBase}/inserir`, projeto);
    },

    atualizarProjeto(projeto) {
        return axiosJwt.post(`${urlBase}/atualizar`, projeto);
    },

    excluirCredores(projetoId) {
        return axiosJwt.patch(`${urlBase}/${projetoId}/excluircredores`)
    }
};
