<template>
    <detalhe titulo="CNPJ" :size="tamanhoTitulos">{{ $toCnpj(projeto?.cnpj) }}</detalhe>
    <detalhe titulo="Nome" :size="tamanhoTitulos">{{ projeto?.nome }}</detalhe>
    <detalhe titulo="Descrição" :size="tamanhoTitulos">{{ projeto?.descricao }}</detalhe>
    <detalhe titulo="Tipo" :size="tamanhoTitulos">{{ projeto?.tipoDescr }}</detalhe>
    <detalhe titulo="Observações" :size="tamanhoTitulos">{{ projeto?.observacoes }}</detalhe>
    <detalhe titulo="Status" :size="tamanhoTitulos">
        <status :status="projeto?.statusDescr"></status>
    </detalhe>
</template>

<script>
export default {
    props: {
        projeto: {
            type: Object,
        },
    },

    data() {
        return {
            tamanhoTitulos: '130',
        };
    },

    methods: {},

    computed: {},
};
</script>
