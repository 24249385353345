<template>
    <Button label="Excluir Credores" icon="pi pi-trash" class="p-ml-2 p-button-danger" @click="confirmarExcluir()" />
</template>

<script>
import ProjetosServices from './services';

export default {
    props: {
        projeto: {
            type: Object,
        },
    },

    emits: ['atualizarErros'],

    methods: {
        confirmarExcluir() {
            this.$confirm.require({
                message: `Tem certeza que deseja excluir TODOS os credores do projeto ${this.projeto.nome}? 
                    Atenção, essa é uma ação irreversível!!!`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.excluir();
                },
            });
        },

        excluir() {
            this.$store.dispatch('addRequest');
            ProjetosServices.excluirCredores(this.projeto.projetoId).then((response) => {
                if (response && response.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Exclusão de Credores',
                        detail: 'Credores excluídos com sucesso',
                        life: 3000,
                    });
                } else {
                    this.$emit('atualizarErros', response.errors);
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
