<template>
    <div class="p-grid" v-show="mostrarProjetoDetalhe">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><font-awesome-icon icon="project-diagram" /> Projeto</h5>
                    <btn-refresh @click="obterProjeto()"></btn-refresh>
                </div>
                <erros-box :erros="erros"></erros-box>
                <div class="p-mb-4">
                    <TabView v-model:activeIndex="activeIndex">
                        <TabPanel header="Dados Gerais">
                            <detalhar-projeto :projeto="projeto"></detalhar-projeto>
                        </TabPanel>
                        <TabPanel header="Documentos">
                            <anexos :projeto="projeto" @obterProjetoAtualizado="obterProjeto()"></anexos>
                        </TabPanel>
                    </TabView>
                </div>
                <btn-atualizar :projeto="projeto" v-if="activeIndex == 0"></btn-atualizar>
                <btn-voltar :route="{ name: 'Projetos' }"></btn-voltar>
                <Button
                    label="Importar planilha"
                    icon="pi pi-upload"
                    class="p-ml-2 p-button-info"
                    @click="toImportarPlanilha()"
                    v-if="$temAcessoView('PROJETOS-04')"
                />
                <btn-excluir-credores
                    :projeto="projeto"
                    v-if="activeIndex == 0 && $temAcessoView('PROJETOS-05')"
                    @atualizarErros="erros = $event"
                ></btn-excluir-credores>
            </div>
        </div>
    </div>
    <router-view :projeto="projeto"></router-view>
</template>

<script>
import ProjetosServices from './services';
import BtnAtualizar from './BtnAtualizar';
import DetalharProjeto from './DetalharProjeto.vue';
import Anexos from './anexos/Listagem.vue';
import BtnExcluirCredores from './BtnExcluirCredores';

export default {
    components: {
        BtnAtualizar,
        DetalharProjeto,
        Anexos,
        BtnExcluirCredores,
    },

    data() {
        return {
            erros: [],
            projeto: null,
            activeIndex: 0,
        };
    },

    computed: {
        mostrarProjetoDetalhe() {
            return this.$route.name == 'Projetos_Detalhar';
        },
    },

    methods: {
        obterProjeto() {
            this.$store.dispatch('addRequest');
            ProjetosServices.obterProjetoPorId(this.$route.params.id).then((response) => {
                if (response && response.success) {
                    this.projeto = response.data;
                } else {
                    this.projeto = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        voltar() {
            this.$router.push({ name: 'Projetos' });
        },

        toImportarPlanilha() {
            this.$router.push({
                name: 'Projetos_ImportarPlanilha',
            });
        },
    },

    mounted() {
        this.obterProjeto();
    },
};
</script>
