import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PROJETOS_PORT}${process.env.VUE_APP_API_PATH}`;
const urlBase = `${api}/projetos/anexos`;

const formDataType = {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
};

export default {
    obterTiposDocumentos() {
        return axiosJwt.get(`${urlBase}/tipo`);
    },

    inserir(anexo) {
        const formData = new FormData();
        formData.append('projetoId', anexo.projetoId);
        formData.append('descricao', anexo.descricao);
        formData.append('arquivo', anexo.arquivo);
        return axiosJwt.post(`${urlBase}/inserir`, formData, formDataType);
    },

    excluir(anexo) {
        return axiosJwt.post(`${urlBase}/excluir`, anexo);
    },

    download(credorAnexoId) {
        return axiosJwt.get(`${urlBase}/${credorAnexoId}/download`, {
            responseType: 'blob',
        });
    },
};
